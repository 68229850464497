import React from "react";
import Panel from "./index";
import myImage from "siteAssets/images/modal/spinner.svg";

const PanelLoading = (props) => {
	const {
		header, //strip out the header
		children,
		className,
		...otherProps
	} = props;

	const panelClassName = ["modal-panel", className].filter((x) => x).join(" ");

	return (
		<Panel
			className={`${panelClassName}`}
			hasBorder={true}
			// header={header}

			{...otherProps}
		>
			{header && <div className="px-3 mb-2 text-center">{header}</div>}

			<div className="text-center mb-2">
				<img src={myImage} alt="Loading Spinner" />
			</div>

			{children && <div className="px-3 text-center">{children}</div>}
		</Panel>
	);
};

export default PanelLoading;
