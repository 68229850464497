import React from "react";
import _ from "lodash";

import database from "./database";
const baseUrl = "./images/";
const oldBaseUrl =
	"https://storage.googleapis.com/ti-freedombrokers/post-fields/images/";

// const generateFullFilename = (imageName) => `${oldBaseUrl}${imageName}`;

// const generateFullFilename = (imageName) =>
// require(`${baseUrl}${imageName}`).default;

const pageManager = (key) => {
	let foundData = database[key];

	// if (!foundData) throw `Error -- can't find ${key} in pageManager`;

	if (!foundData) {
		const msg = `pageManager -- can't find ${key}`;
		// console.log(`["${key}"]:{ headingText: "${key}"}`);
		// console.warn(msg);
		throw msg;
	}
	// else if (foundData.headerImages) {
	// 	["desktop", "tablet", "mobilePortrait", "mobileLandscape", "icon"]
	// 		.filter((x) => x in foundData.headerImages)
	// 		.forEach((filename) => {
	// 			foundData.headerImages[filename] = generateFullFilename(
	// 				foundData.headerImages[filename]
	// 			);
	// 		});
	// }

	return foundData;
};

export default pageManager;

export const getAll = () => {
	const ProcessItem = (src) =>
		src ? (
			<span className="bg-primary p-3">
				<img src={src} />
			</span>
		) : null;

	return Object.entries(_.cloneDeep(database))
		.map(([key, data]) => ({ ...data.headerImages, key }))
		.filter((x) => x != undefined)
		.map((x, i) => {
			console.log("getAll:", x);
			return (
				<div className="row" key={i}>
					<h4>{x.key}</h4>
					{ProcessItem(x.desktop)}
					{ProcessItem(x.tablet)}
					{ProcessItem(x.mobilePortrait)}
					{ProcessItem(x.mobileLandscape)}
					{ProcessItem(x.icon)}
				</div>
			);
		});
};
